import Loading from 'components/global/layout/Loading';
import { useGetUserQuery } from 'components/profile/userSlice';
import React from 'react';
// import WelcomePage from './WelcomePage';

import OnboardingDetails from './OnboardingDetails';
import { useGetOnboardingQuery } from './panelSlice';

const Index = () => {
  const { data: userData, isLoading: userLoading } = useGetUserQuery();
  const { data: onbordingData, isLoading: onbordingLoading } =
    useGetOnboardingQuery();
  return (
    <>
      {(userLoading || onbordingLoading) && <Loading />}
      {userData && onbordingData && (
        <OnboardingDetails onbordingData={onbordingData} userData={userData} />
      )}
    </>
    // <WelcomePage />
  );
};

export default Index;
