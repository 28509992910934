import { useState } from 'react';
import Progress from './Progress';
import Questions from './Questions';

const database = [
  {
    questionNum: 1,
    question:
      'To what extent did the project go beyond surface-level information and provide a deeper understanding of the topic?',
    questionType: 'radio',
  },
  {
    questionNum: 2,
    question:
      'How would you rate the accuracy and sufficiency of the resources presented in addition to task explanations?',
    questionType: 'radio',
  },
  {
    questionNum: 3,
    question:
      'How well was the project presented with clear and effective visual aids, such as graphs, charts, and tables?',
    questionType: 'radio',
  },
  {
    questionNum: 4,
    question:
      'To what extent was the language used clear, concise, and easy to understand?',
    questionType: 'radio',
  },
  {
    questionNum: 5,
    question:
      'How up-to-date and relevant was the information to current industry trends and developments?',
    questionType: 'radio',
  },
  {
    questionNum: 6,
    question:
      'To what extent did the project enable your skill development and meet your experiential learning goals?',
    questionType: 'radio',
  },
  {
    questionNum: 7,
    question:
      'How easy was the layout and organization of the platform to navigate?',
    questionType: 'radio',
  },
  {
    questionNum: 8,
    question: 'Overall, how would you rate your experience of the platform?',
    questionType: 'radio',
  },
];

const Index = ({ enrollmentId, setShowFeedback }) => {
  const [currentQuestion, setCurrentQuestion] = useState(1);
  return (
    <div className='text-gray-900'>
      <div className='p-4 space-y-4  mx-4 md:mx-auto border shadow rounded-xl'>
        <Progress currentQuestion={currentQuestion} database={database} />
        <Questions
          setShowFeedback={setShowFeedback}
          enrollmentId={enrollmentId}
          database={database}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
        />
      </div>
    </div>
  );
};

export default Index;
