import React from 'react';
import AllScores from './AllScores';
import Milestones from './Milestones';
import UpcomingEngagements from './UpcomingEngagements';

const OnboardingDetails = ({ userData, onbordingData }: any) => {
  return (
    <div>
      <div className='md:flex space-x-4'>
        <div className='md:w-3/5'>
          <AllScores userData={userData} onbordingData={onbordingData} />
        </div>
        <div className='md:w-2/5 mt-10 md:mt-0'>
          <Milestones userData={userData} onbordingData={onbordingData} />
          <UpcomingEngagements />
        </div>
      </div>
    </div>
  );
};

export default OnboardingDetails;
