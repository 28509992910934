import { format } from 'date-fns';
import { useState } from 'react';
// @ts-ignore: Unreachable code error
import ReactStars from 'react-rating-stars-component';
import classNames from 'classnames';
import { useAddEventFeedbackMutation } from '../vga/userBatchSlice';

const feedbackText = [
  'Improvement required in sessions content & relevancy',
  'Improvement required in facilitators domain knowledge',
  'Improvement required in punctunality & accessibility',
  'All good, you are doing a great job!',
];

const EngagementPopup = ({ closeModal, event, setIsFeedbackRequired }: any) => {
  const [text, setText] = useState('');
  const [reting, setReting] = useState(0);
  const [manualFeedback, setManualFeedback] = useState('');
  const isDisabled = !(text && reting);
  const [loading, setLoading] = useState(false);

  const [submitFeedback] = useAddEventFeedbackMutation();
  function submitFn() {
    setLoading(true);
    submitFeedback({
      eventId: event.id,
      feedback: { text, reting, manualFeedback },
    })
      .unwrap()
      .then((res: any) => {
        setIsFeedbackRequired(false);
        closeModal();
      })
      .catch((res: any) => {
        setLoading(false);
        closeModal();
      });
  }

  return (
    <div className='p-4'>
      <div className='flex justify-between'>
        <p className='font-semibold'>Your Feedback Is Valuable</p>
        <button
          onClick={() => closeModal()}
          className='text-lg font-semibold ml-8'
        >
          X
        </button>
      </div>
      <div className='border mt-4 p-2 md:p-4 flex justify-between text-primary'>
        <div className='text-sm md:text-base'>
          <p>{event.title} </p>
          <p>
            {event.dateTime &&
              format(new Date(event.dateTime), 'do LLLL yyyy p')}
          </p>
        </div>

        <ReactStars
          count={5}
          onChange={(newRating: any) => setReting(newRating)}
          size={36}
          // isHalf={true}
          emptyIcon={<i className='far fa-star'></i>}
          halfIcon={<i className='fa fa-star-half-alt'></i>}
          fullIcon={<i className='fa fa-star'></i>}
          activeColor='#ffd700'
        />
      </div>
      <p className='font-semibold my-4'>Please select one</p>
      <div className='grid grid-cols-1 md:grid-cols-2 text-primary font-medium gap-4'>
        {feedbackText.map((texts) => {
          return (
            <div
              key={texts}
              onClick={() => setText(texts)}
              className={classNames('border p-4 cursor-pointer rounded w-72', {
                'text-white bg-primary ': texts === text,
              })}
            >
              <p>{texts} </p>
            </div>
          );
        })}
      </div>
      <div className='my-4'>
        <textarea
          className='shadow p-2 border border-gray-400 focus:border-black rounded mx-2 w-full h-40'
          name='theFeedback'
          placeholder='Feedback'
          onChange={(e: any) => setManualFeedback(e.target.value)}
        />
      </div>
      <div className='w-full mt-4 flex justify-end'>
        <button
          onClick={() => submitFn()}
          disabled={isDisabled || loading}
          className={classNames(
            'px-4 md:px-8 py-1 font-semibold  rounded text-white',
            {
              'bg-gray-200 ': isDisabled,
              'bg-primary': !isDisabled,
            }
          )}
        >
          {loading ? 'Loading' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default EngagementPopup;
