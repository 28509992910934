import React from 'react';
const cardData = [
  {
    id: 1,
    question: 'What?',
    text: 'An opportunity to connect with a Company Mentor and gain insights about the Industry, Market & the Company',
  },
  {
    id: 2,
    question: 'Why?',
    text: 'Facilitates communication to build a professional credibility, get culture exposure  and strengthen the network',
  },
  {
    id: 3,
    question: 'How?',
    text: 'Interactions take place via scheduled connects through the dashboard and written communication via the Query section',
  },
];

const MentorConnects: React.FC<any> = ({ mentorConnectProps }) => {
  return (
    <div className='mt-4 md:mt-6'>
      <p className='font-semibold text-textColor-light'>Mentor Connects</p>
      <div className='grid grid-cols-1 xl:grid-cols-3 gap-4  xl:gap-12 lg:w-[90%] lg:mr-auto mt-6'>
        {cardData.map((data) => (
          <div
            key={data.id}
            className='flex space-x-2 items-center lg:items-start border rounded-lg p-2 md:p-4 '
          >
            <div className='flex-none font-semibold bg-primary-light  text-white h-12 md:h-14 w-12 md:w-14 text-sm flex items-center my-auto justify-center rounded-full'>
              {data.question}
            </div>
            <p className='text-sm pl-2'>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MentorConnects;
