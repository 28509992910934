import React from 'react';
import DownloadCertificate from './DownloadCertificate';
import Performance from './Performance';
import ScoreCertificationHeader from './ScoreCertificationHeader';
import { ProjectTaskType } from '../types';
import { useGetUserProjectQuery } from '../userBatchSlice';

const ScoreCertificationPanel = ({
  batchData,
  data,
  projectData,
  feedbackGiven,
}: {
  batchData: any;
  data: any;
  projectData: ProjectTaskType;
  feedbackGiven: any;
}) => {
  // const myProjectData = { ...projectData };

  const { isSuccess, data: userProjectData } = useGetUserProjectQuery({
    batchId: batchData?._id,
  });

  //if Ids match,then it means the solution is present for 21st century lab task
  let centurySubmission = 0;
  const centuryLabPresent = (centuryTaskId: string) => {
    const res = userProjectData?.find((single: any) => {
      return single.projectId === centuryTaskId;
    });
    if (res?.tasksubmission?.length > 0) {
      return centurySubmission++;
    }
  };

  //if Ids match,then it means the solution is present for project/company task
  let companySubmission = 0;
  const companyPresent = (companyTaskId: string) => {
    const res = userProjectData?.find((single: any) => {
      return single.projectId === companyTaskId;
    });
    companySubmission = res?.tasksubmission?.length || 0;
  };

  //finding  21st century lab tasks
  const totalCenturyTask = batchData?.projects?.filter((project: any) => {
    return project?.projectId?.type === '21st Century Skills';
  });

  //finding  project/company tasks
  const totalCompanyTask = batchData?.projects?.filter((project: any) => {
    return project?.projectId?.type === 'Company Tasks';
  });

  //gives the id of 21st century lab task, to use in Find Above
  totalCenturyTask?.map((centuryTask: any) => {
    return centuryLabPresent(centuryTask.projectId._id);
  });

  //gives the id of project/company task, to use in Find Above
  totalCompanyTask?.map((companyTask: any) => {
    return companyPresent(companyTask.projectId._id);
  });

  //total number of submitted tasks
  let totalSubmittedTasks = centurySubmission + companySubmission;

  //total number of tasks
  let totalTasks =
    totalCenturyTask.length + totalCompanyTask[0].projectId.tasks.length;

  //Submit Percentage
  const submitPercentage = (totalSubmittedTasks / totalTasks) * 100;

  const isAllTaskDone = submitPercentage >= 100;

  const userSubmissionRateData = {
    submitPercentage,
    centurySubmission,
    companySubmission,
    totalSubmittedTasks,
    totalTasks,
    totalCenturyTask: totalCenturyTask.length,
    totalCompanyTask: totalCompanyTask[0].projectId.tasks.length,
  };

  return (
    <>
      {isSuccess && (
        <div className='md:border rounded-b-lg text-textColor-lighter'>
          <ScoreCertificationHeader
            batchId={data?.batches[0]?._id}
            userSubmissionRateData={userSubmissionRateData}
          />
          <div className='px-4 md:px-8 space-y-10'>
            <Performance
              batchData={batchData}
              userSubmissionRateData={userSubmissionRateData}
            />
            {batchData?.status === 'Completed' && (
              <DownloadCertificate
                batchData={batchData}
                feedbackGiven={feedbackGiven}
                batchId={data?.batches[0]?._id}
                isAllTaskDone={isAllTaskDone}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ScoreCertificationPanel;
