import { BellIcon } from '@heroicons/react/20/solid';
import { AboutCoachDataType } from '../../types';
import CoachContent from './CoachContent';

const AboutCoach = ({
  coachName,
  coachDesignation,
  coachImage,
  coachLinkedin,
  coachExpertiseArea,
  coachContent,
  setIsOpen,
  notificationsExist,
}: AboutCoachDataType & { setIsOpen: (value: boolean) => void } & {
  notificationsExist: boolean;
}) => {
  return (
    <div className='mt-4 md:mt-6'>
      <p className='font-semibold'>About Coach</p>
      <div className='p-2 md:p-6  mt-4 md:mt-6 border rounded-lg'>
        <div>
          <div className='flex space-x-4 items-start'>
            <img
              src={coachImage}
              alt='coach-pic'
              className='w-16 md:w-24 rounded-full'
            />
            <div>
              <div className='flex space-x-2 items-center'>
                <p className='text-primary font-semibold sm:text-lg'>
                  {coachName}
                </p>
                <div>
                  <a
                    href={coachLinkedin}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <img
                      src='https://res.cloudinary.com/belong/image/upload/v1670235654/new%20b2b/Vector_3_cbmzi2.svg'
                      alt='linkedin-logo'
                    />
                  </a>
                </div>
                <div>
                  {notificationsExist ? (
                    <BellIcon
                      className='h-5 w-5 text-primary cursor-pointer'
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <p className='text-primary text-sm sm:text-base '>
                {coachDesignation}
              </p>
              <div>
                <CoachContent coachProps={coachContent} />
              </div>
            </div>
          </div>
          <div className='text-primary sm:flex flex-wrap sm:flex-nowrap space-x-4 items-baseline text-sm sm:text-base  mt-2 md:mt-4 lg:mt-0 text-center space-y-2 sm:space-y-4 '>
            <p className='font-semibold'>Domain Expertise</p>
            {coachExpertiseArea.map((area) => {
              return (
                <p
                  key={area}
                  className='border rounded-2xl border-primary px-4 py-1'
                >
                  {area}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCoach;
