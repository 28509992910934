import React from 'react';
import AttendanceCard from './layout/AttendanceCard';
import DomainCard from './layout/DomainCard';
import ProjectScoreCard from './layout/ProjectScoreCard';
import SubmissionCard from './layout/SubmissionCard';

const Performance = ({
  batchData,
  userSubmissionRateData,
}: {
  batchData: any;
  userSubmissionRateData: any;
}) => {
  return (
    <div>
      <p className='fold-bold text-lg'>VGA Performance</p>
      <p className='text-primary font-semibold text-lg my-4'>
        Your submission rate needs to be 100% to access the certificate
      </p>
      {/* individual performance card start */}
      <div className='mt-6 sm:flex flex-wrap gap-2 lg:gap-4 space-y-4 sm:space-y-0 '>
        <AttendanceCard batchData={batchData} />
        <SubmissionCard userSubmissionRateData={userSubmissionRateData} />
        <DomainCard />
        <ProjectScoreCard batchId={batchData._id} />
      </div>
      {/* individual performance card ends */}
    </div>
  );
};

export default Performance;
