import React from 'react';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import domainData from 'components/jobs/domainData.json';
import useDomainTestContext from 'hooks/useDomainTestContext';
import { useDomainTestScoreQuery } from '../DomainQuizSlice';
import { differenceInCalendarDays } from 'date-fns';

const SelectDomain = () => {
  function cutOffDateCleared(lastAttemptDate: string) {
    return (
      differenceInCalendarDays(new Date(), new Date(lastAttemptDate)) >= 15
    );
  }

  const { isLoading, data } = useDomainTestScoreQuery();

  function checkIfLocked(data: any) {
    if (data.totalAttemptsGiven === 1 && !cutOffDateCleared(data.startTime)) {
      return true;
    }
    if (data.totalAttemptsGiven === 2) {
      return true;
    }
  }

  function checkIfScoreCanBeShown(data: any) {
    if (data.totalAttemptsGiven) {
      return true;
    }
  }

  const showScore = data
    ?.filter((data: any) => checkIfScoreCanBeShown(data))
    .map((data: any) => data.domain._id);

  const lockedDomains = data
    ?.filter((data: any) => checkIfLocked(data))
    .map((data: any) => data.domain._id);

  // const [loading, setloading] = useState(false);
  const { state, dispatch } = useDomainTestContext();
  const navigate = useNavigate();

  // async function domainTestAttempt() {
  //   setloading(true);
  //   const res = await axiosApi.get('/test/domain-test/attempt-status', {
  //     params: { areaId: state.areaId },
  //   });
  //   if (res.status === 200) {
  //     if (res.data.data.attemptLeft || res.data.data.testInProgress) {
  //       navigate('../select-skills');
  //     } else {
  //       navigate('/belong-score/domain/scorecard');
  //     }
  //   }
  //   setloading(false);
  // }

  return (
    <div>
      <p className='text-primary font-manrope lg:font-semibold font-normal lg:text-xl  text-base text-center lg:mt-12 mt-6'>
        Pick your Domain
      </p>
      <div className='font-manrope font-normal text-sm text-center mb-2 lg:leading-9 leading-6'>
        <p>
          Choose the domain of area of expertise that you are currently working
          in or aspire to work in.
        </p>
        <p>You can select one domain in this step.</p>
      </div>
      {isLoading && <p>Loading...</p>}
      <div
        className={classNames(
          { hidden: isLoading },
          'grid grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-4 md:gap-2 bg-gray-50 p-8'
        )}
      >
        {domainData.map((domain: any) => {
          return (
            <div
              onClick={() => {
                if (!lockedDomains.includes(domain.id) && data?.length < 3) {
                  dispatch({
                    type: 'UPDATE_AREA_ID',
                    payload: domain.id,
                  });
                }
              }}
              key={domain.id}
              className={classNames(
                'w-32 mx-auto cursor-pointer rounded p-2 space-y-2 flex flex-col justify-betwee',
                {
                  'bg-primary': state.areaId === domain.id,
                },
                {
                  'bg-primary-lightest': !(state.areaId === domain.id),
                }
              )}
            >
              <img className=' mx-auto' alt='domain' src={domain.image} />
              <p className='text-center'>{domain.name}</p>
              {showScore?.includes(domain.id) && (
                <Link
                  to={`/quiz/domain/scorecard/${domain.id}`}
                  className={classNames(
                    'text-center text-xs my-2',
                    { 'text-primary': !(state.areaId === domain.id) },
                    {
                      'text-white': state.areaId === domain.id,
                    }
                  )}
                >
                  View Score
                </Link>
              )}
            </div>
          );
        })}
      </div>
      <div className={classNames('text-center', { hidden: !state.areaId })}>
        <button
          onClick={() => {
            navigate('../select-skills');
          }}
          disabled={isLoading}
          className='font-manrope lg:mt-10 mt-4 text-primary font-bold text-xl border-2 border-[#878787]/50 lg:px-14 px-6 py-2 rounded-xl '
        >
          {isLoading ? 'Loading...' : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default SelectDomain;
