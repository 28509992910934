import React, { useMemo } from 'react';
import { setToken } from 'components/auth/authSlice';
import { useDispatch } from 'react-redux';
import Routes from './routes';

const Auth = ({ auth0user }) => {
  const email = auth0user.email || '';
  const userEmail = email.toLowerCase();
  const dispatch = useDispatch();
  useMemo(() => {
    dispatch(setToken(userEmail));
  }, [dispatch, userEmail]);
  return <Routes />;
};

export default Auth;
