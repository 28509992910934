import React, { useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import GuidelineText from './GuidelineText';
import IntroText from './IntroText';
import NavigationBar from './NavigationBar';
// import QuillSection from './QuillSection';
import TemplateText from './TemplateText';
import UserSolution from '../../project-tasks/second-task-view/UserSolution';
import QuillSection from '../../project-tasks/second-task-view/QuillSection';
import FeedbackSection from '../../project-tasks/second-task-view/FeedbackSection';
import AdditionalFeedback from '../../project-tasks/second-task-view/AdditionalFeedback';
import { FaRegEdit } from 'react-icons/fa';

const Index = ({
  setIsLabView,
  projectDetails,
}: {
  setIsLabView: any;
  projectDetails: any;
}) => {
  const taskData = projectDetails.tasks[0];
  const projectId = projectDetails._id;
  const batchId = projectDetails.batchId;
  const isPrjectLive = new Date(taskData.dueDateTime) > new Date();
  const [isEdit, setEdit] = useState(false);
  return (
    <div className='p-4 md:p-8  md:border rounded-b-lg pb-8 '>
      <div className='flex space-x-6 mt-6 md:mt-10  border-b-2'>
        <div
          className='flex space-x-1  cursor-pointer items-center text-primary'
          onClick={() => setIsLabView(null)}
        >
          <IoIosArrowBack />
          <p>Back</p>
        </div>
        <NavigationBar />
      </div>
      <IntroText projectDetails={projectDetails} />
      <TemplateText template={projectDetails.template} />
      <GuidelineText taskData={projectDetails.tasks[0]} />
      {!taskData.solution || isEdit ? (
        <>
          <QuillSection
            solution={taskData.solution}
            batchId={batchId}
            projectId={projectId}
            taskId={taskData._id}
            setIsTaskView={setIsLabView}
            dueDateTime={taskData.dueDateTime}
          />
        </>
      ) : (
        <>
          {isPrjectLive && (
            <div className='flex justify-end mt-4 -mb-12 mr-2'>
              <button
                className='text-primary flex '
                onClick={() => setEdit(true)}
              >
                <FaRegEdit className='mt-1 mr-1' /> <p>Edit</p>
              </button>
            </div>
          )}
          <UserSolution solution={taskData.solution} />
        </>
      )}
      {taskData?.score && taskData?.score?.total && (
        <>
          <FeedbackSection score={taskData?.score} />
          <AdditionalFeedback coachNote={taskData?.coachNote} />
        </>
      )}
    </div>
  );
};

export default Index;
