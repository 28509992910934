import { axiosUpload } from 'config/Axios';
import { Formik, Field, Form } from 'formik';
import { useAddFeedbackMutation } from '../capstoneSlice';
import Navigation from './Navigation';
import { useState } from 'react';

const Questions = (props) => {
  const [addFeedback] = useAddFeedbackMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const question = props.database[props.currentQuestion - 1];
  const radioInputs = (
    <>
      <div className='md:flex flex-wrap md:space-x-6 text-sm'>
        <div className='space-x-1'>
          <Field
            type={question.questionType}
            id='1'
            name={question.question}
            value='1'
          />
          <label htmlFor='1'>1</label>
        </div>
        <div className='space-x-1'>
          <Field
            type={question.questionType}
            id='2'
            name={question.question}
            value='2'
          />
          <label htmlFor='2'>2</label>
        </div>
        <div className='space-x-1'>
          <Field
            type={question.questionType}
            id='3'
            name={question.question}
            value='3'
          />
          <label htmlFor='3'>3</label>
        </div>
        <div className='space-x-1'>
          <Field
            type={question.questionType}
            id='4'
            name={question.question}
            value='4'
          />
          <label htmlFor='4'>4</label>
        </div>
        <div className='space-x-1'>
          <Field
            type={question.questionType}
            id='5'
            name={question.question}
            value='5'
          />
          <label htmlFor='5'>5</label>
        </div>
      </div>
      <div className='mt-3 text-primary text-sm'>
        Rating Scale Range- 1: Lowest to 5: Highest
      </div>
    </>
  );
  const textInputs = (
    <Field
      as='textarea'
      type={question.questionType}
      name={question.question}
      className='border rounded-md p-4 h-36 w-[90%] '
    />
  );
  const booleanInputs = (
    <div className='flex flex-wrap space-x-4'>
      <div className='space-x-1'>
        <Field type='radio' id='yes' name={question.question} value='YES' />
        <label htmlFor='yes'>Yes</label>
      </div>
      <div className='space-x-1'>
        <Field type='radio' id='no' name={question.question} value='NO' />
        <label htmlFor='no'>No</label>
      </div>
    </div>
  );

  async function uploadFile(files, setFieldValue) {
    if (files.size > 2200000) {
      setErrorMessage('file must be less then 2 MB');
    } else {
      const fileExtension = files.name.substring(
        files.name.lastIndexOf('.') + 1
      );
      const formData = new FormData();
      formData.append('file', files);
      formData.append('field', 'PROFILE_IMAGE');
      formData.append('fileExtension', fileExtension);
      const result = await axiosUpload.post('/aws-upload/single', formData);
      if (result.status === 200) {
        setFieldValue('image', result.data.publicUrl);
      } else {
        setErrorMessage('Please try after some time');
      }
    }
  }
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        const feedback = {};
        Object.keys(values).forEach((key) => {
          feedback[key] = +values[key];
        });

        if (values.image) {
          feedback.image = values.image;
        }
        const data = { feedback, enrollmentId: props.enrollmentId };
        addFeedback(data)
          .unwrap()
          .then((res) => {
            props.setShowFeedback(false);
          });
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div>
            <div key={question.questionNum} className='space-y-6'>
              <p>{question.question}</p>
              <div>
                {question.questionType === 'radio' ? radioInputs : null}
                {question.questionType === 'text' ? textInputs : null}
                {question.questionType === 'yesNo' ? booleanInputs : null}
                {question.questionNum === 14 &&
                  values[
                    'Do you share your consent to feature your experience as a testimonial on our platforms?'
                  ] === 'YES' && (
                    <div>
                      <div>
                        <div className='flex justify-between w-full text-primary mb-4'>
                          Choose a picture for testimonial:
                        </div>
                        <input
                          type='file'
                          accept='image/png, image/jpeg'
                          onChange={(e) => {
                            const file = e.target.files[0];
                            uploadFile(file, setFieldValue);
                          }}
                        />

                        <div className='text-xs mt-1'>
                          Please upload png or jpeg file, Max size: 2 MB
                        </div>
                        <div className='mt-2 text-red-600'>{errorMessage}</div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <Navigation
            values={values}
            questionName={question.question}
            database={props.database}
            currentQuestion={props.currentQuestion}
            setCurrentQuestion={props.setCurrentQuestion}
          />
        </Form>
      )}
    </Formik>
  );
};

export default Questions;
