import { apiSlice } from '../../../api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSummary: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/user/profile/score-summary',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Summary'],
    }),
    getOnboarding: builder.query<any, void>({
      query: () => {
        return {
          url: '/user/onboard/summary',
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Onboarding'],
    }),
    getCapstoneInfo: builder.query<any, void>({
      query: () => {
        return {
          url: '/user/onboard/capstone-status',
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['CapstoneInfo'],
    }),
  }),
});

export const {
  useGetSummaryQuery,
  useGetOnboardingQuery,
  useGetCapstoneInfoQuery,
} = extendedApiSlice;
