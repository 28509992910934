import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { BsJournalBookmark, BsQuestionCircle } from 'react-icons/bs';
import { BannerType } from '../types';
import { useGetCountriesQuery } from '../userBatchSlice';

const Banner: React.FC<BannerType> = ({
  programTitle,
  companyName,
  companyLogo,
  companyCountry,
  domain,
  guidebook,
  batchName,
}) => {
  const { data } = useGetCountriesQuery({});

  let countryCode = '';
  if (data) {
    countryCode = Object.keys(data).filter((el: any) => {
      return data[el]?.name === companyCountry;
    })[0];
  }

  return (
    <div className='px-6 py-4 bg-dark/[.01] mb-2 rounded-xl'>
      <div className='flex justify-between'>
        <div className='lg:flex items-center gap-4'>
          <p className='font-semibold'>
            {programTitle} with {companyName}
            <p className='text-sm'>{batchName}</p>
          </p>
          <p className='px-2 md:px-8 lg:px-20 py-2 border max-w-fit  border-primary rounded-full inline-block text-sm text-primary'>
            {domain}
          </p>
        </div>

        <div className='flex items-center gap-4'>
          <div className='flex gap-4 text-primary text-xl font-bold'>
            <a href={guidebook} target='_blank' rel='noreferrer noopenener'>
              <BsJournalBookmark />
            </a>
            <a
              href='https://drive.google.com/file/d/1TCaLjgigl6qMKL8Q3EveiD_mH4knV-ds/view?usp=sharing'
              target='_blank'
              rel='noreferrer noopenener'
            >
              <BsQuestionCircle />
            </a>
          </div>
          <img src={companyLogo} alt='Logo' className='h-6' />
          {countryCode && (
            <ReactCountryFlag
              countryCode={countryCode}
              // countryCode='IN'
              svg
              style={{
                width: '2em',
                height: '2em',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
