import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useGetUserBatchQuery } from 'components/dashboard/vga/userBatchSlice';
import { API_URL } from '../../../../../config/API_LINKS';

const Navigation = (props) => {
  const { data, refetch } = useGetUserBatchQuery({});
  const [serverResponse, setServerResponse] = useState('');
  const submitQuestions = () => {
    setServerResponse('Submitting your responses');
    axios
      .post(API_URL + '/v2/batch/vga-quiz', {
        responses: props.finalSubmission,
        userId: data?._id,
        startTime: parseInt(localStorage.getItem('localVgaCountDownTime')),
        finishTime: +Date.now(),
        batchId: props.batchId,
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem('localVgaQuestion');
          localStorage.removeItem('localVgaCountDownTime');
          localStorage.removeItem('localVgaSubmission');
          localStorage.removeItem('localVgaCurrentQuestion');
          refetch();
          setServerResponse('Submission successful!, Generating report');
        }

        //Show report from response
      })
      .catch((res) => setServerResponse('Could not submit, please try again.'));
  };

  useEffect(() => {
    if (props.autoSubmit) {
      submitQuestions();
    }
  }, [props.autoSubmit]);

  const navStyle = 'font-semibold text-primary cursor-pointer';

  const setPrevious = () => {
    localStorage.setItem('localVgaCurrentQuestion', props.currentQuestion);
    props.currentQuestion > 0 &&
      props.setCurrentQuestion(props.currentQuestion - 1);
  };
  const setNext = () => {
    if (props.currentQuestion + 1 < props.totalQuestions) {
      localStorage.setItem('localVgaCurrentQuestion', props.currentQuestion);
      props.setCurrentQuestion((pre) => pre + 1);
    } else {
      submitQuestions();
    }
  };

  return (
    <div>
      <div className='fixed bottom-0 right-1 left-1 flex z-10 w-full sm:static'>
        <div className='justify-between flex bg-gray-50 p-6 w-full mr-4 text-2xl font-semibold'>
          <p
            className={navStyle}
            onClick={() => {
              setPrevious();
            }}
          >
            {props.currentQuestion > 0 ? 'Previous' : ''}
          </p>
          <div
            className={navStyle}
            onClick={() => {
              setNext();
            }}
          >
            {props.currentQuestion + 1 < props.totalQuestions ? (
              <p>Next</p>
            ) : (
              <p className='bg-primary-light p-2 rounded'>Submit</p>
            )}
          </div>

          {props.currentQuestion + 1 === props.totalQuestions && (
            <p
              className={navStyle}
              onClick={() => {
                props.setShowPreview(true);
              }}
            >
              Preview
            </p>
          )}
        </div>
      </div>

      <p className='my-2'>{serverResponse}</p>
    </div>
  );
};

export default Navigation;
