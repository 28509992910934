const CompanyDataHeader = (props: any) => {
  const total = props.eventData.length;
  const attended = props.eventData.filter(
    (event: any) => event.attendance === 1
  ).length;
  return (
    <div className='md:flex flex-wrap md:justify-between bg-gradient-to-b from-primary/10 to-white p-4 md:px-8 md:pt-6'>
      <div className='flex space-x-2 md:space-x-4 items-center text-primary'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670234241/new%20b2b/carbon_location-company_pk9dgt.svg'
          alt='icon'
        />
        <p className='text-sm font-semibold'>
          Your Internship Company & Mentor
        </p>
      </div>

      <div className='mt-2 md:mt-0'>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Background Insights{' '}
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Company Mentorship{' '}
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Connects Attended-{' '}
          <span className='text-primary'>
            {attended}/{total}
          </span>
        </p>
      </div>
    </div>
  );
};

export default CompanyDataHeader;
