const CenturyLabHeader = () => {
  return (
    <div className='md:flex flex-wrap md:justify-between bg-gradient-to-b from-primary/10 to-white p-4 md:px-8 md:pt-6'>
      <div className='flex space-x-2 md:space-x-4 items-center text-primary'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670571704/new%20b2b/ic_outline-assignment_twvgff.svg'
          alt='icon'
        />
        <p className='text-sm font-semibold'>
          Your Enterprenuial & Employability Tools From The Masterclass
        </p>
      </div>

      <div className='mt-2 md:mt-0'>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          21st Century Practical Skillset{' '}
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Career & Interpersonal Growth{' '}
        </p>
        <p className='text-sm hidden'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Assignments Completed-<span className='text-primary'> 2/6</span>
        </p>
      </div>
    </div>
  );
};

export default CenturyLabHeader;
