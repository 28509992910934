import { BasicDetailsContext } from 'hooks/useBasicDetailsContext';
import { getBannerData } from './helper';
import Banner from './journey/Banner';
import TabsBar from './layout/TabsBar';
import useTabs from './layout/useTabs';
import { BannerType, InitialTab } from './types';

const Dashboard: React.FC<any> = ({ data }) => {
  const bannerProps: BannerType = getBannerData(data?.batches[0]);
  const [state, dispatch] = useTabs(data);
  const [activeTab] = state.tabs.filter(
    (tab: InitialTab) => tab.status === 'ACTIVE'
  );

  const coachNotifications = state?.basicDetails?.coachNotifications;
  return (
    <div>
      <BasicDetailsContext.Provider
        value={{ basicDetails: state?.basicDetails, dispatch }}
      >
        <Banner {...bannerProps} />
        <div className='sticky top-0'>
          <TabsBar
            tabs={state.tabs}
            dispatch={dispatch}
            coachNotifications={coachNotifications}
          />
        </div>
        {activeTab.component}
      </BasicDetailsContext.Provider>
    </div>
  );
};

export default Dashboard;
