const ProjectDataHeader = ({ projectData }: { projectData: any }) => {
  const total = projectData?.tasks?.length;
  const completed = projectData?.tasks?.filter((task: any) => {
    return task.solution;
  }).length;
  return (
    <div className='md:flex flex-wrap md:justify-between bg-gradient-to-b from-primary/10 to-white p-4 md:px-8 md:pt-6'>
      <div className='flex space-x-2 md:space-x-4 items-center text-primary'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670304537/new%20b2b/ant-design_project-outlined_naw4pp.svg'
          alt='icon'
        />
        <p className='text-sm font-semibold'>
          Your Tasks From The Apprenticeship Company Project
        </p>
      </div>

      <div className='mt-2 md:mt-0'>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Live Industry Project{' '}
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Domain & Skill Knowledge{' '}
        </p>

        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Tasks Completed-{' '}
          <span className='text-primary'>
            {completed}/{total}
          </span>
        </p>
      </div>
    </div>
  );
};

export default ProjectDataHeader;
