import React, { useState } from 'react';
import QuizScoreChart from './QuizScoreChart';
import { Link, useParams } from 'react-router-dom';

type scoreProps = {
  data: any;
};

const ShowDomainScore = (props: scoreProps) => {
  const params = useParams();
  const areaId = params?.areaId;
  const selectedArea = props.data?.filter(
    (data: any) => data.domain._id === areaId
  )[0];
  const [currentDomainId, setCurrentData] = useState(
    selectedArea ? areaId : props.data[0].domain._id
  );

  const currentData = props.data.filter((domain: any) => {
    return domain.domain._id === currentDomainId;
  })[0];

  const percentage = currentData.percentage;

  function getSkillPercentage(skillId: any) {
    const currentSkill = currentData.finalScore.filter((skill: any) => {
      return skill.skillId === skillId;
    })[0];
    const catPercentage = Math.ceil(
      (currentSkill.obtained * 100) / currentSkill.max
    );
    return catPercentage;
  }
  const color = percentage > 25 ? '#C2FFFB' : '#EC9B9B';

  return (
    <div className='m-1 sm:m-4'>
      <div className='flex justify-end'>
        <select
          value={currentDomainId}
          onChange={(e) => setCurrentData(e.target.value)}
        >
          {props.data.map((domain: any, index: number) => {
            return (
              <option key={index} value={domain.domain._id}>
                {domain.domain.title}
              </option>
            );
          })}
        </select>
      </div>
      <div className='hidden text-gray-500 font-semibold m-2'>
        Congratulations! You can start Applying to Opportunities.
      </div>
      <div className='bg-gray-50 p-4 mt-8'>
        <div className='sm:flex text-gray-500 font-semibold'>
          <div>
            <div className='mb-6 text-center '>Your Score Card</div>
            <div className='flex justify-center'>
              <QuizScoreChart color={color} percentage={percentage} />
            </div>
          </div>
          <div className='mt-12 sm:ml-16'>
            <div className='mb-4'>
              The Assessment assesses on the {currentData.skills.length} skill
              expertise as picked by you:
              <span className='text-primary'>
                {currentData.skills.map((skill: any) => {
                  return <span key={skill._id}> {skill.title}, </span>;
                })}
              </span>
            </div>
            <ul className='list-disc ml-8'>
              {currentData.skills.map((skill: any) => {
                return (
                  <li key={skill._id}>
                    {skill.title} -{' '}
                    <span className='text-primary'>
                      {getSkillPercentage(skill._id)} %
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className='flex justify-center mt-4 sm:mt-12'>
        <Link
          to='/'
          className='px-12 py-2 font-semibold text-xl text-primary border border-gray-400 rounded-xl shadow'
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default ShowDomainScore;
