import React from 'react';
import { Link } from 'react-router-dom';

import { FaRegClock, FaTasks, FaPencilRuler } from 'react-icons/fa';
import classNames from 'classnames';

interface allProps {
  data: any;
}

const CapstoneInfo = (props: allProps) => {
  const data = props.data;
  const totalTime =
    data?.tasks && data?.tasks.length
      ? data?.tasks?.map((i: any) => i.time).reduce((a: any, b: any) => a + b)
      : 0;
  const totalSlill =
    data?.tasks && data?.tasks.length
      ? data?.tasks
          ?.map((i: any) => i.skillId.length)
          .reduce((a: any, b: any) => a + b)
      : 0;
  return (
    <div
      className={classNames(
        ' px-2 md:px-4 border-b border-gray-400 bg-gradient-to-t from-gray-100 to-primary-lightest',
        {
          'pb-4': !Boolean(data?.capstoneImage),
        }
      )}
    >
      <div className='flex justify-between '>
        <div>
          <div className='flex text text-sm mt-4'>
            <div>
              <Link to='/capstone' className=' hover:text-blue-700'>
                Home
              </Link>
            </div>
            <div>
              <span className=' mx-2'>{`>`}</span>
            </div>
            <div>
              <Link to='/capstone' className=' hover:text-blue-700'>
                Capstone
              </Link>
            </div>
          </div>
          <div className='mt-4'>
            <div className='font-semibold md:text-xl lg:text-3xl'>
              {data.title}
            </div>
          </div>
          <div className='mt-4 flex '>
            <div>
              <div className='flex font-semibold text-gray-600'>
                <FaRegClock className='mt-1 mr-2' />
                <p> {totalTime} hours</p>
              </div>
              <div className='flex font-semibold text-gray-600 mt-1'>
                <FaPencilRuler className='mt-1 mr-2' />
                <p> {totalSlill} Skills </p>
              </div>
            </div>
            <div className='ml-8'>
              <div className='flex font-semibold text-gray-600'>
                <FaTasks className='mt-1 mr-2' />
                <p> {data.tasks.length} Tasks </p>
              </div>
              <div className='inline-flex font-semibold space-x-1  mt-1'>
                <img
                  src='https://res.cloudinary.com/belong/image/upload/v1661953024/capstone/image_285_cyfenz.png'
                  alt='basic'
                />
                <p className='text-gray-600'> {data.level} </p>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-flow-col  '>
          <div>
            <div className='text-center text-primary md:font-semibold my-14 md:mr-10 bg-white border border-primary px-2 sm:px-4 py-1 rounded-2xl'>
              {data.domainId.title}
            </div>
          </div>
          {data?.capstoneImage && (
            <img
              src={data?.capstoneImage}
              alt='person'
              className='mx-auto h-60 object-cover hidden sm:block'
            />
          )}
          <img
            src={data.companyId.logoUrl}
            alt='company'
            className='mx-auto mt-4 h-20 md:mr-8 mb-8 hidden sm:block'
          />
        </div>
      </div>
    </div>
  );
};

export default CapstoneInfo;
