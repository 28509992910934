import Loading from 'components/global/layout/Loading';
// import Routes from './routes';
import { useAuth0 } from '@auth0/auth0-react';
import Auth from './Auth';

function App() {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  if (isLoading)
    return (
      <div>
        <Loading />
      </div>
    );
  if (!isLoading && !isAuthenticated) return loginWithRedirect();
  if (isAuthenticated && user) return <Auth auth0user={user}></Auth>;
}

export default App;
