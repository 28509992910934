import { useGetUserProjectQuery } from '../../userBatchSlice';

const ProjectScoreCard = ({ batchId }: { batchId: string }) => {
  const { data = [], isSuccess } = useGetUserProjectQuery({ batchId });

  type DataObject = {
    challenge: { text: string; total: number };
    outcome: { text: string; total: number };
    potential: { text: string; total: number };
    problem: { text: string; total: number };
    solution: { text: string; total: number };
  };
  const summary: DataObject = {
    challenge: {
      text: 'Challenge Addressal',
      total: 0,
    },
    outcome: {
      text: 'Outcome Evaluation',
      total: 0,
    },
    potential: {
      text: 'Potential Solutions Evaluation',
      total: 0,
    },
    problem: {
      text: 'Problem Analysis',
      total: 0,
    },
    solution: {
      text: 'Strategy and Implementation',
      total: 0,
    },
  };

  let submissions = 0;
  let total = 0;
  data.forEach((task: any) => {
    if (task.tasksubmission.length > 0) {
      task.tasksubmission.forEach((submission: any) => {
        if (submission.score) {
          submissions++;
          summary.challenge.total += submission.score.challangeAddressal;
          summary.outcome.total += submission.score.outcomeEvaluation;
          summary.potential.total +=
            submission.score.potentialSolutionsEvaluation;
          summary.problem.total += submission.score.problemStatementAnalysis;
          summary.solution.total += submission.score.solutionStrategy;
          total += submission.score.total;
        }
      });
    }
  });

  const keys = Object.keys(summary) as (keyof DataObject)[];

  return (
    <>
      {isSuccess && submissions > 0 && (
        <div className='border-2 border-dark/10 rounded-sm space-y-6 p-2 pb-6 w-80  min-h-[300px] '>
          {/* first area start */}
          <div className='flex justify-between items-center'>
            <p className='text-primary font-semibold text-lg'>
              Project Evaluation
            </p>
            {/* <img
    src='https://res.cloudinary.com/belong/image/upload/v1670569049/new%20b2b/akar-icons_eye_kun9w1.svg'
    alt='eye'
    className='w-4 h-4 lg:w-6 lg:h-6'
  /> */}
          </div>
          {/* first area end */}

          {/* second area start */}
          <div>
            <p className='text-white font-semibold bg-primary border rounded-2xl min-w-fit px-2 py-1 text-center'>
              Project Performance - {(total / submissions).toFixed(2)}%
            </p>
          </div>
          {/* second area end */}
          {/* third area start */}
          <div className='space-y-4 font-semibold'>
            {keys.map((key: keyof DataObject, index: number) => {
              const text = summary[key].text;
              const average = summary[key].total / submissions;
              return (
                <div
                  key={index}
                  className='bg-primary/10 border  rounded-2xl flex items-center  h-8 w-full'
                >
                  <p className='bg-white  border-2  border-primary rounded-full h-11 w-11 p-1 text-primary flex flex-none items-center justify-center'>
                    {average}
                  </p>
                  <div className='flex w-full items-center justify-between'>
                    <p className='ml-4'>{text}</p>
                  </div>
                </div>
              );
            })}
          </div>
          {/* third area end */}
        </div>
      )}
    </>
  );
};

export default ProjectScoreCard;
