import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { store } from 'redux/store';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';

import { QueryClient, QueryClientProvider } from 'react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain='dev-h6o6kyky.us.auth0.com'
      clientId='GfnI0HYt2c9G8LDAb6r4jO1XOVt1PDrG'
      authorizationParams={{
        redirect_uri: 'https://herovired.belong.education',
        // redirectUri='http://localhost:3000'
      }}
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
