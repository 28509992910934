import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { PREFIX } from 'config/API_LINKS';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: PREFIX + '/api/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth?.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('x-belong-herovired-email', `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    'User',
    'NewCapstoneQuiz',
    'CurrentCapstone',
    'CurrentCapstoneQuiz',
    'UserCapstoneSkill',
    'UserBatch',
    'UserSubmission',
    'UserTicket',
    'Summary',
    'UserApplications',
    'FeaturedJobs',
    'NewVgaQuiz',
    'CurrentVgaQuiz',
    'VgaTestScore',
    'Onboarding',
    'CapstoneInfo',
    'NewAptitudeQuiz',
    'CurrentAptitudeQuiz',
    'Partner',
    'Industry',
    'NewDomainQuiz',
    'CurrentDomainQuiz',
    'DomainTestScore',
    'Capstone',
    'MyCapstone',
    'AllCapstones',
    'CapstoneFilter',
    'Announcements',
  ],
  endpoints: (builder) => ({}),
});
