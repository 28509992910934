const Achievements = ({ data }: { data: any }) => {
  const certificates = data?.certificates || [];
  return (
    <div className='px-6 lg:pr-16 py-7 border-b-2 lg:border-l border-primary min-h-[270px]'>
      <div className='flex space-x-2 items-center'>
        <div className='border-r-2 pr-4 font-semibold text-xl'>
          Certificates & Achievements
        </div>
        <p className='text-sm'>
          {certificates.length > 0 && certificates.length}
        </p>
      </div>
      {/* box containing project info */}
      {certificates.map((project: any, index: any) => {
        return (
          <div
            className='h-32 p-4 mx-2 sm:mx-0 my-6 rounded-xl bg-primary-lightest '
            key={index}
          >
            <div className={`flex mr-auto items-center`}>
              <div className='basis-4/5'>
                <p className='font-semibold  mb-2'>{project.title} </p>
                <p className='text-sm text-black mb-1'>{project.organizer}</p>
                <p className='text-xs  mb-2'>{project.period}</p>
              </div>
              <div className='flex basis-1/5'>
                {project.certificateUrl && (
                  <a
                    href={project.certificateUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='font-semibold text-xs underline'
                  >
                    View Details
                  </a>
                )}
              </div>
            </div>
            <div>{project.description}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Achievements;
