import { useRef } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import { format } from 'date-fns';

const CapstoneCertificate = (props) => {
  const capstoneData = props.capstoneData;
  const companyName = capstoneData?.companyId?.name;
  const companyLogo = capstoneData?.companyId?.logoUrl;
  const domainName = capstoneData?.domainId?.title;
  const pdfExportComponent = useRef(null);
  const contentArea = useRef(null);
  const partnerLogo = props.partnerLogo;

  const handleExportWithMethod = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
    props.setIsOpen(false);
  };
  const certificateText = 'CERTIFICATE OF COMPLETION';
  const finalText = `successfully completing his/her tasks for ${capstoneData.title} with ${companyName} in ${domainName}`;
  const user = props.user;

  return (
    <>
      <div className='flex flex-col items-center p-2 border-2 border-b-0 bg-white'>
        <button
          onClick={handleExportWithMethod}
          className='visible rounded mt-3 text-gray-100  text-sm px-8 py-2 bg-primary hover:bg-primary-light hover:text-gray-700 font-bold transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105'
        >
          Download Certificate
        </button>
      </div>
      <div style={{ width: '1000px' }} className='bg-white'>
        <PDFExport paperSize='auto' landscape={true} ref={pdfExportComponent}>
          <div ref={contentArea} className='flex justify-center m-4 '>
            <div
              className='border-2 border-black bg-no-repeat'
              style={{
                backgroundImage:
                  "url('https://res.cloudinary.com/belong/image/upload/v1643360408/uploaded_resources/VGA_ETP_Certificates_sc8nf1.png')",
              }}
            >
              <div className='p-6 my-4'>
                <div className='flex justify-end gap-8  mb-12'>
                  <img
                    src='https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png'
                    alt='Logo'
                    className='h-12'
                  />
                  <img src={partnerLogo} alt='Logo' className='h-12' />
                </div>
                <div className='grid grid-cols-4 gap-1 px-4'>
                  <div className='flex flex-col items-center justify-between'>
                    <img
                      // src='https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png'
                      src={companyLogo}
                      alt='Logo'
                      className='h-12 mt-40'
                    />
                    <div className=''>
                      <div className='flex flex-col justify-center'>
                        <img
                          src='https://res.cloudinary.com/belong/image/upload/v1643353663/uploaded_resources/One_no4yyb.gif'
                          alt='Logo'
                          className='h-12'
                        />
                        <p className='italic text-center'> & </p>
                        <img
                          src='https://res.cloudinary.com/belong/image/upload/v1643353575/uploaded_resources/Two_yy5ee6.gif'
                          alt='Logo'
                          className='h-10'
                        />
                      </div>
                      <p className='border-2 border-b-0 border-l-0 border-r-0 border-primary font-bold tracking-wider text-gray-700 pt-2 text-center text-sm'>
                        BELONG CO-FOUNDERS
                      </p>
                    </div>
                  </div>
                  <div className='col-span-3 pr-10 mb-12'>
                    <p className='font-extrabold  text-2xl mb-8 text-gray-700 border-b-4 border-gray-700 flex-auto tracking-widest'>
                      {certificateText}
                    </p>
                    <p className='text-primary font-semibold tracking-widest mb-8 text-lg'>
                      Capstone
                    </p>
                    <p className='text-gray-700 font-semibold tracking-widest mb-8'>
                      THIS IS PRESENTED TO{' '}
                    </p>
                    <p className='text-primary font-bold tracking-widest text-3xl mb-6'>
                      {user?.firstName} {user?.lastName}
                    </p>
                    <p className='px-16 italic font-semibold text-gray-700 text-lg'>
                      for
                    </p>
                    <p className='text-gray-700 mt-6 tracking-widest font-semibold text-sm '>
                      {finalText}
                    </p>
                    <div className='mt-12 mr-8 flex justify-end text-sm'>
                      Date of Issue:{' '}
                      {format(new Date(Date.now()), 'do LLLL yyyy')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PDFExport>
      </div>
    </>
  );
};

export default CapstoneCertificate;
