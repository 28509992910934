import React from 'react';
import { formatDistance } from 'date-fns';
import Reply from './Reply';
import UserComment from './UserComment';
import AssigneeComment from './AssigneeComment';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';

const QueryReply = ({
  SetIsReplySelected,
  ticket,
}: {
  SetIsReplySelected: any;
  ticket: any;
}) => {
  const ticketDate = formatDistance(new Date(ticket.createdAt), new Date(), {
    addSuffix: true,
  });
  const comment = ticket.activity.filter((act: any) => {
    return act.comment;
  });

  return (
    <div className='border mt-4 md:mt-8 p-2 md:p-4'>
      <div className='flex justify-end'>
        <p onClick={() => SetIsReplySelected(null)} className='cursor-pointer'>
          X
        </p>
      </div>
      <div className='border rounded mt-2 mx-2 md:mx-8'>
        <div className='lg:flex lg:justify-between p-2 md:p-6 bg-gray-50'>
          <div className='space-y-2'>
            <p className='font-semibold'>{ticket.subject}</p>
            <p className='text-sm text-textColor-lighter'>
              {ticket.description}
            </p>
          </div>
          <div className='flex  mt-2 lg:mt-0 items-center'>
            <p className='flex-none px-4 py-1 font-medium md:font-semibold border border-primary rounded-2xl text-sm  bg-white text-primary'>
              {ticketDate}
            </p>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-4'>
          {ticket.attachments?.map((imageUrl: any, index: any) => {
            return (
              <div key={index} className='flex flex-col items-center mt-8'>
                <img
                  src={`${UPLOAD_OR_DOWNLOAD_URL}/${imageUrl}`}
                  alt='img'
                  className='rounded object-cover'
                />
              </div>
            );
          })}
        </div>
        {comment.length > 0 && (
          <div className='shadow border w-full p-4 rounded-xl mb-3 bg-white'>
            <p className='text-gray-600 font-bold mb-6 text-sm'>Response</p>
            {comment.map((com: any) => {
              let commentTitle = '';
              if (com.owner === 'user') {
                commentTitle = 'You';
              } else {
                commentTitle = ticket.department;
              }
              return (
                <>
                  {com.owner !== 'user' ? (
                    <AssigneeComment com={com} commentTitle={commentTitle} />
                  ) : (
                    <UserComment com={com} commentTitle={commentTitle} />
                  )}
                </>
              );
            })}
          </div>
        )}
      </div>
      <Reply
        ticketId={ticket._id}
        status={ticket.status}
        SetIsReplySelected={SetIsReplySelected}
      />
    </div>
  );
};

export default QueryReply;
