import { InitialTab } from '../types';

const btnClass =
  'px-4 lg:px-8 py-2  rounded bg-textColor-main[.1] text-sm  shrink-0';
const activeClass =
  'px-4 lg:px-8 py-2  rounded bg-primary/50 font-semibold shrink-0';

const TabsBar = ({
  tabs,
  dispatch,
  coachNotifications,
}: {
  tabs: InitialTab[];
  dispatch: any;
  coachNotifications: number;
}) => {
  const notification = (
    <span className='shadow bg-primary text-white h-5 w-5 text-xs inline-flex items-center justify-center rounded-full  ml-1'>
      {coachNotifications}
    </span>
  );
  return (
    <div className='shadow flex overflow-x-auto bg-white'>
      {tabs.map((tab: InitialTab) => (
        <button
          key={tab.id}
          className={tab.status === 'ACTIVE' ? activeClass : btnClass}
          onClick={() => {
            dispatch({ type: 'CHANGE_TAB', payload: tab.id });
          }}
        >
          {tab.text}
          {/* Show notifications only on the Coach Support Tab */}
          {tab.id === 4 && coachNotifications > 0 && notification}
        </button>
      ))}
    </div>
  );
};

export default TabsBar;
