import React, { useState } from 'react';
import Buttons from '../button/button';
import Company from '../screens/Company/Company';
import Coach from '../screens/Coach/Coach';
import MasterClass from '../screens/MasterClass/MasterClass';
import Calender from '../screens/Calender/Calender';
import Project from '../screens/Project/Project';
import Assignments from '../screens/Assignment/Assignment';
import RightSection from './RightSection';
import Feedback from '../feedback';
import TimeSheet from '../screens/TimeSheet';
import LeftSection from './LeftSection';
import { checkForUser } from '../helper/CheckForUser';
import QuerySystem from '../screens/querySystem';
import ButtonEtpVga from '../button/ButtonEtpVga';
import FiibButton from '../button/FiibButton';
import VgaQuiz from '../screens/vgaQuiz';

const Main = ({ data }) => {
  const [selectedScreen, setSelectedScreen] = useState(0);
  const partnerId = data.batches[0].partner;
  const batchDetails = data.batches[0];
  const batchId = batchDetails._id;
  const isCareereraUser = partnerId === '6319e62c68c34c0016a7a85d';

  if (batchDetails.length === 0) {
    return (
      <p>
        No data available, please contact support at connect@belong.education
      </p>
    );
  }
  const userDetails = data.batches[0].users.filter(
    (user) => user.email === data.email
  )[0];

  const dt1 = new Date(batchDetails.startDate);
  const dt2 = new Date();

  const totalDays = Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
  const currentWeek =
    Math.ceil((totalDays + 1) / 7) > 0 ? Math.ceil((totalDays + 1) / 7) : 1;
  const isFullJourney =
    batchDetails.timeSheet &&
    batchDetails.timeSheet.startsAt &&
    currentWeek >= batchDetails.timeSheet.startsAt;
  const fullJourneyStart =
    batchDetails.timeSheet && batchDetails.timeSheet.startsAt;

  const showTimeSheet = userDetails.model === 'Default' && isFullJourney;
  const showCertificate = batchDetails.status === 'Completed';
  const showEtp = [
    '61b1f1a75f0c8000238c8c23',
    '6245be20433baa001697de30',
  ].includes(batchDetails._id);
  const showTicket = batchDetails.enableQueryManagement;
  const isVGE = batchDetails._id === '61e11897945b22002350275b';
  const isHeroVired = batchDetails._id === '62133fea104c4d0016d6d400';
  //SJCC batch also added
  const isSunstone = [
    '62bfd54a5c795700162d4675',
    '62bff6dd5c795700162d596c',
    '62c025ec5c795700162d66cd',
    '62c7df06646da50016708a3e',
  ].includes(batchDetails._id);

  const isFiibBatch = [
    '63073f2291ba220016694215',
    '63089f3428b0ae0016bd0cda',
  ].includes(batchDetails._id);

  const userCompany = userDetails.company._id;
  const userMentor = userDetails.mentor._id;
  const userCoach = userDetails.coach._id;
  const userArea = userDetails.area._id;
  const userCompany2 = userDetails.company2 ? userDetails.company2._id : null;
  const userMentor2 = userDetails.mentor2 ? userDetails.mentor2._id : null;
  const userCoach2 = userDetails.coach2 ? userDetails.coach2._id : null;

  const allTasks = data.batches[0].tasks.filter(
    (task) => task.assignmentId !== null
  );

  const allEvents = data.batches[0].events.filter(
    (event) => event.eventId !== null
  );

  const userSpecificTasks = allTasks.filter((task) => {
    if (isFullJourney && task.assignmentId.week >= fullJourneyStart) {
      return (
        checkForUser(task.mentorId, userMentor2 || userMentor) &&
        checkForUser(task.coachId, userCoach2 || userCoach) &&
        checkForUser(task.areas, userArea) &&
        checkForUser(task.companyId, userCompany2 || userCompany)
      );
    } else {
      return (
        checkForUser(task.companyId, userCompany) &&
        checkForUser(task.mentorId, userMentor) &&
        checkForUser(task.coachId, userCoach) &&
        checkForUser(task.areas, userArea)
      );
    }
  });

  const userSpecificEvents = allEvents.filter((event) => {
    if (isFullJourney && event.eventId.week >= fullJourneyStart) {
      return (
        checkForUser(event.companyId, userCompany2 || userCompany) &&
        checkForUser(event.mentorId, userMentor2 || userMentor2) &&
        checkForUser(event.areas, userArea) &&
        checkForUser(event.coachId, userCoach2 || userCoach)
      );
    } else {
      return (
        checkForUser(event.companyId, userCompany) &&
        checkForUser(event.mentorId, userMentor) &&
        checkForUser(event.areas, userArea) &&
        checkForUser(event.coachId, userCoach)
      );
    }
  });

  const userAllTaskId = userSpecificTasks
    .filter((task) => task.assignmentId.status === 'publish')
    .map((task) => task.assignmentId._id);
  const userSubmissionsId = data.taskSubmissions.map(
    (submission) => submission.assignmentId
  );

  const allTaskDone = userAllTaskId.every((task) =>
    userSubmissionsId.includes(task)
  );
  return (
    <>
      {showEtp && !userDetails.program ? (
        <div>Batch Completed</div>
      ) : (
        <div>
          <div className='p-4 bg-gray-100 border-b-2 border-gray-200 flex flex-col sm:flex-row'>
            <LeftSection
              batchDetails={batchDetails}
              userDetails={userDetails}
              isFullJourney={isFullJourney}
              isVGE={isVGE}
              isHeroVired={isHeroVired}
              isFiibBatch={isFiibBatch}
            />
            <RightSection
              guidebook={batchDetails.guidebook}
              videoLink={batchDetails.tutorial}
              partnerId={partnerId}
              fullTimeGuidebook={
                batchDetails.timeSheet && batchDetails.timeSheet.guidebook
              }
              isVGE={isVGE}
              isHeroVired={isHeroVired}
            />
          </div>

          <div className='z-30 top-24 sticky'>
            {showEtp || isVGE || isHeroVired || isSunstone ? (
              <ButtonEtpVga
                selectedScreen={selectedScreen}
                setSelectedScreen={setSelectedScreen}
                showCertificate={showCertificate}
                isVGE={isVGE}
                showTicket={showTicket}
                showEtp={showEtp}
                isSunstone={isSunstone}
              />
            ) : isFiibBatch ? (
              <FiibButton
                selectedScreen={selectedScreen}
                setSelectedScreen={setSelectedScreen}
                showCertificate={showCertificate}
                showTicket={showTicket}
              />
            ) : (
              <Buttons
                selectedScreen={selectedScreen}
                setSelectedScreen={setSelectedScreen}
                batchId={batchId}
                showTimeSheet={showTimeSheet}
                showCertificate={showCertificate}
                showTicket={showTicket}
              />
            )}
          </div>
          <div>
            {selectedScreen === 0 && (
              <Calender
                data={data}
                currentWeek={currentWeek}
                userSpecificEvents={userSpecificEvents}
                userSpecificTasks={userSpecificTasks}
                showEtp={showEtp}
                startDate={batchDetails.startDate}
                isVGE={isVGE}
                isCareereraUser={isCareereraUser}
              />
            )}
            {selectedScreen === 1 && (
              <Company
                data={data}
                isFullJourney={isFullJourney}
                userSpecificEvents={userSpecificEvents}
                isCareereraUser={isCareereraUser}
              />
            )}
            {selectedScreen === 2 && (
              <Project
                data={data}
                isFullJourney={isFullJourney}
                userSpecificTasks={userSpecificTasks}
                isCareereraUser={isCareereraUser}
              />
            )}
            {selectedScreen === 3 && (
              <MasterClass
                userSpecificEvents={userSpecificEvents}
                isVGE={isVGE}
                isCareereraUser={isCareereraUser}
              />
            )}
            {selectedScreen === 4 && (
              <Assignments
                data={data}
                userSpecificTasks={userSpecificTasks}
                isVGE={isVGE}
                isCareereraUser={isCareereraUser}
              />
            )}
            {selectedScreen === 5 && (
              <Coach
                data={data}
                isFullJourney={isFullJourney}
                userSpecificEvents={userSpecificEvents}
                isVGE={isVGE}
                isCareereraUser={isCareereraUser}
              />
            )}
            {selectedScreen === 6 && (
              <Feedback
                data={data}
                userDetails={userDetails}
                allTaskDone={allTaskDone}
                isVGE={isVGE}
                showEtp={showEtp}
              />
            )}
            {selectedScreen === 7 && (
              <TimeSheet
                batchDetails={batchDetails}
                userDetails={userDetails}
                currentWeek={currentWeek}
                cognitoId={data.cognitoId}
                userId={data._id}
              />
            )}
            {selectedScreen === 8 && (
              <QuerySystem
                cognitoId={data.cognitoId}
                userId={data._id}
                userDetails={userDetails}
                batchId={batchId}
                partner={partnerId}
                isVGE={isVGE}
                isFiibBatch={isFiibBatch}
                isCareereraUser={isCareereraUser}
              />
            )}

            {selectedScreen === 11 && (
              <VgaQuiz
                userDetails={userDetails}
                batchId={batchId}
                currentWeek={currentWeek}
                totalWeek={batchDetails.week}
                isCareereraUser={isCareereraUser}
                isFullJourney={isFullJourney}
                fullJourneyStart={fullJourneyStart}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Main;
