import { Fragment, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ChartBarIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';
import { AiOutlineLogout } from 'react-icons/ai';
// import { Auth } from 'aws-amplify';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useGetUserQuery } from 'components/profile/userSlice';
import Loading from 'components/global/layout/Loading';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';
import { useGetOnboardingQuery } from 'components/dashboard/panel/panelSlice';
import { partnerIds } from 'config/common';
import { useAuth0 } from '@auth0/auth0-react';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function DashboardLayoutNew() {
  const { logout } = useAuth0();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data, isLoading, isSuccess } = useGetUserQuery();
  const onboardingData = useGetOnboardingQuery();
  const profileImage = data?.imageUrl
    ? UPLOAD_OR_DOWNLOAD_URL + '/' + data?.imageUrl
    : 'https://res.cloudinary.com/belong/image/upload/v1658898215/uploaded_resources/933-9332131_profile-picture-default-png_hmyowh.jpg';
  const sidebartabs = data?.partnerId?.sidebarTab;

  const aptitudeGiven = onboardingData.data?.aptitude?.totalAttempts > 0;
  const domainGiven = onboardingData.data?.domain?.totalAttempts > 0;
  const profileFilled = onboardingData.data?.profileStrength >= 5;
  const isOldConsoleflareUser =
    data?.partnerId?._id === partnerIds.consoleflare &&
    new Date('2023-06-02T00:30:00.000Z') > new Date(data?.createdAt);
  const openCapstones =
    aptitudeGiven && profileFilled && (domainGiven || isOldConsoleflareUser);

  const desktopLogo =
    data?.partnerId?.logoUrl ||
    'https://res.cloudinary.com/belong/image/upload/v1638202033/uploaded_resources/Belong_logo_qiv6fv.png';

  const mobileLogo =
    data?.partnerId?.logoUrl ||
    'https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png';

  const isEnrolled = Boolean(data?.batches.length);
  const navigation = useMemo(() => {
    const tabs = [
      {
        name: 'Dashboard',
        href: '/',
        icon: HomeIcon,
        current: pathname === '/',
        disabled: false,
      },
    ];

    sidebartabs?.capstone &&
      tabs.push({
        name: 'Capstones',
        href: '/capstone',
        icon: UsersIcon,
        current: pathname === '/capstone',
        disabled: !openCapstones,
      });

    sidebartabs?.vga &&
      tabs.push({
        name: 'Apprenticeship',
        href: '/dashboard',
        icon: FolderIcon,
        current: pathname === '/dashboard',
        disabled: !isEnrolled,
      });

    sidebartabs?.job &&
      tabs?.push({
        name: 'Jobs',
        href: '/jobs',
        icon: ChartBarIcon,
        current: pathname === '/jobs',
        disabled: false,
      });

    return tabs;
  }, [sidebartabs, pathname, isEnrolled, openCapstones]);

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && (
        <div>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-40 lg:hidden'
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='transition-opacity ease-linear duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity ease-linear duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
              </Transition.Child>

              <div className='fixed inset-0 z-40 flex'>
                <Transition.Child
                  as={Fragment}
                  enter='transition ease-in-out duration-300 transform'
                  enterFrom='-translate-x-full'
                  enterTo='translate-x-0'
                  leave='transition ease-in-out duration-300 transform'
                  leaveFrom='translate-x-0'
                  leaveTo='-translate-x-full'
                >
                  <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-white'>
                    <Transition.Child
                      as={Fragment}
                      enter='ease-in-out duration-300'
                      enterFrom='opacity-0'
                      enterTo='opacity-100'
                      leave='ease-in-out duration-300'
                      leaveFrom='opacity-100'
                      leaveTo='opacity-0'
                    >
                      <div className='absolute top-0 right-0 -mr-12 pt-2'>
                        <button
                          type='button'
                          className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className='sr-only'>Close sidebar</span>
                          <XMarkIcon
                            className='h-6 w-6 text-white'
                            aria-hidden='true'
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className='h-0 flex-1 overflow-y-auto pt-5 pb-4'>
                      <div className='flex flex-shrink-0 items-center px-4'>
                        <a href='https://vlearnv.herovired.com/'>
                          <img
                            src={desktopLogo}
                            alt='Hero'
                            className='hidden md:block w-40'
                          />
                          <img
                            src={mobileLogo}
                            alt='Hero'
                            className='md:hidden w-16'
                          />
                        </a>
                      </div>
                      <nav className='mt-5 space-y-1 px-2'>
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.disabled
                                ? 'pointer-events-none bg-gray-50 text-gray-400'
                                : item.current
                                ? 'bg-primary-lighter text-gray-900'
                                : 'text-gray-600 hover:bg-primary-lightest hover:text-gray-900',
                              'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
                            )}
                          >
                            {item.disabled ? (
                              <LockClosedIcon
                                className={classNames(
                                  'text-gray-300   mr-3 h-6 w-6 flex-shrink-0'
                                )}
                                aria-hidden='true'
                              />
                            ) : (
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? 'text-gray-500'
                                    : 'text-gray-400 group-hover:text-gray-500',
                                  'mr-3 h-6 w-6 flex-shrink-0'
                                )}
                                aria-hidden='true'
                              />
                            )}
                            {item.name}
                          </Link>
                        ))}
                      </nav>
                    </div>
                    <div className='flex flex-shrink-0 border-t border-gray-200 p-4'>
                      <span className='group block flex-shrink-0'>
                        <div className='flex items-center'>
                          <div>
                            <img
                              src={profileImage}
                              alt='profile'
                              className='h-12 w-12 rounded-full'
                            />
                          </div>
                          <div className='mx-3'>
                            <p className='text-base font-medium text-gray-700 group-hover:text-gray-900'>
                              {data?.firstName} {data?.lastName}
                            </p>
                          </div>
                          <AiOutlineLogout
                            onClick={() =>
                              logout({
                                logoutParams: {
                                  returnTo: 'https://vlearn.herovired.com',
                                },
                              })
                            }
                            className='text-2xl cursor-pointer font-bold text-primary -rotate-90'
                          />
                        </div>
                      </span>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className='w-14 flex-shrink-0'>
                  {/* Force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className='hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col'>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className='flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white'>
              <div className='flex flex-1 flex-col overflow-y-auto pt-5 pb-4'>
                <div className='flex flex-shrink-0 items-center px-4'>
                  <a href='https://vlearnv.herovired.com/'>
                    <img
                      src={desktopLogo}
                      alt='Belong'
                      className='hidden md:block w-40'
                    />
                    <img
                      src={mobileLogo}
                      alt='Belong'
                      className='md:hidden w-16'
                    />
                  </a>
                </div>
                <nav className='mt-5 flex-1 space-y-1 bg-white px-2'>
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.disabled
                          ? 'pointer-events-none bg-gray-50 text-gray-400'
                          : item.current
                          ? 'bg-primary-lighter text-gray-900'
                          : 'text-gray-600 hover:bg-primary-lightest hover:text-gray-900',
                        'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
                      )}
                    >
                      {item.disabled ? (
                        <LockClosedIcon
                          className={classNames(
                            'text-gray-300   mr-3 h-6 w-6 flex-shrink-0'
                          )}
                          aria-hidden='true'
                        />
                      ) : (
                        <item.icon
                          className={classNames(
                            item.current
                              ? 'text-gray-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'mr-3 h-6 w-6 flex-shrink-0'
                          )}
                          aria-hidden='true'
                        />
                      )}
                      {item.name}
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className='flex flex-1 flex-col lg:pl-64'>
            <div className='sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden'>
              <button
                type='button'
                className='-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
                onClick={() => setSidebarOpen(true)}
              >
                <span className='sr-only'>Open sidebar</span>
                <Bars3Icon className='h-6 w-6' aria-hidden='true' />
              </button>
            </div>
            <main className='flex-1'>
              <div className='pb-6'>
                <div className='ml-auto max-w-7xl px-4 sm:px-6'>
                  <div className='flex flex-shrink-0 p-4'>
                    <span className='group block w-full flex-shrink-0'>
                      <div className='flex items-center justify-end gap-4'>
                        <Link to='/profile' className='flex items-center gap-2'>
                          <div>
                            <img
                              className='inline-block h-9 w-9 rounded-full'
                              src={profileImage}
                              alt='profile'
                            />
                          </div>
                          <p className='text-sm font-medium text-gray-700 group-hover:text-gray-900'>
                            {data?.firstName} {data?.lastName}
                          </p>
                        </Link>
                        <AiOutlineLogout
                          onClick={() =>
                            logout({
                              logoutParams: {
                                returnTo: 'https://vlearn.herovired.com',
                              },
                            })
                          }
                          className='text-2xl cursor-pointer font-bold text-primary -rotate-90'
                        />
                      </div>
                    </span>
                  </div>
                </div>
                <div className='mx-auto max-w-7xl px-4 sm:px-6'>
                  <Outlet />
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </>
  );
}
