import React, { useState } from 'react';
import TextEditorQuill from 'components/capstone/capstoneView/taskView/TextEditorQuill';
import { Dialog } from '@headlessui/react';
import { useSubmitTaskMutation } from '../../userBatchSlice';
import { format } from 'date-fns';

const QuillSection = ({
  setIsTaskView,
  taskId,
  batchId,
  projectId,
  dueDateTime,
  solution,
}: {
  setIsTaskView: any;
  taskId: string;
  batchId: string;
  projectId: string;
  dueDateTime: string;
  solution: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [userSolution, setUserSolution] = useState('');
  const [submitTask] = useSubmitTaskMutation();
  function submitTaskFn() {
    setLoading(true);
    submitTask({
      batchId,
      projectId,
      taskId,
      solution: userSolution,
    })
      .unwrap()
      .then((res: any) => {
        setIsTaskView(null);
      });
  }
  return (
    <div className='mt-4'>
      <div className='sm:flex justify-between'>
        <div className='font-semibold text-lg'>Task Solution</div>
        <p className='text-primary font-semibold'>
          Due Date:{' '}
          {dueDateTime && format(new Date(dueDateTime), 'do LLLL yyyy h:m b')}
        </p>
      </div>

      <TextEditorQuill
        initialtext={solution || ''}
        setUserSolution={setUserSolution}
      />
      <div className='text-center  mt-16'>
        <button
          onClick={() => setIsOpen(true)}
          // onClick={() => submitTaskFn()}
          className='px-8 py-1 bg-primary-light rounded-lg mt-4'
          disabled={loading}
        >
          {loading ? 'Loading' : 'Submit'}
        </button>
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          as='div'
          className='relative z-10'
        >
          <div className='fixed top-1/3  md:left-1/3 lg:left-1/2 overflow-y-auto overflow-x-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <div>
                <Dialog.Panel className='rounded bg-white m-4 p-4'>
                  <div className='text-left w-72 sm:w-96'>
                    <div className='font-bold'>
                      Are you sure you want to submit the task?
                    </div>
                    <div className='mt-4'>
                      You can not make any changes in your task once you have
                      submitted the task
                    </div>
                    <div className='mt-6 px-2 md:px-4 py-3 flex justify-around md:justify-end'>
                      <button
                        type='button'
                        className='w-1/3 lg:w-1/2   text-center rounded-md bg-red-500 px-2 lg:px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm'
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type='button'
                        className='w-1/3 lg:w-1/2   text-center rounded-md border border-gray-300 bg-primary-light px-2 lg:px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                        onClick={() => {
                          submitTaskFn();
                          setIsOpen(false);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default QuillSection;
