// import { FeedbackFormType } from 'components/dashboard/vga/types';
import { useState } from 'react';
import Progress from './Progress';
import Questions from './Questions';

const database = [
  {
    category: 'upskilling',
    question:
      'The apprenticeship met your expectations and/or objectives you had before joining.',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'upskilling',
    question:
      'I gained practical learning that the university course does not offer.',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'upskilling',
    question:
      'The experience was relevant to my career choice and progression.',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'masterclass',
    question: 'I found the Masterclasses engaging and interactive',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'masterclass',
    question:
      'How effective were the instructors in facilitating the Apprenticeship?',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'companyTasks',
    question:
      'The company project tasks were sucessful in upskilling your practical domain knowledge.',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'companyTasks',
    question:
      'I was required to work independently on the project tasks to research, startegise, implement and deploy the solution.',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'companyTasks',
    question:
      'Were the Problem statement and the learning objectives clearly communicated at the start of the apprenticeship?',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'coach',
    question:
      "How satisfied were you with the coach's knowledge and expertise in the subject area?",
    questionType: 'radio',
    value: '',
  },
  {
    category: 'coach',
    question:
      'Did the coach provide clear and effective explanations and examples?',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'companyMentor',
    question:
      'Mentors provided significant support in completing company tasks',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'companyMentor',
    question: 'I found the Mentor Connect interactive and informative',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'futureReadiness',
    question:
      'After the apprenticeship, I feel better equipped to perform in a professional setup or environment.',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'futureReadiness',
    question: 'I got exposure into the global ecosystem and culture',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'recommendationRating',
    question:
      'How likely are you to recommend this apprenticeship to someone who is looking to be more employable?',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'testimonial',
    question:
      'Could you leave a short testimonial summarising your experience and learning from the journey?',
    questionType: 'text',
    value: '',
  },
  {
    category: 'testimonial',
    question:
      'Do you share your consent to feature your experience as a testimonial on our platforms?',
    questionType: 'yesNo',
    value: '',
  },
];

const Index = ({ batchId }: { batchId: string }) => {
  const [currentQuestion, setCurrentQuestion] = useState(1);

  return (
    <div className='text-gray-900'>
      <div className='p-4 space-y-4  mx-4 md:mx-auto '>
        <Progress currentQuestion={currentQuestion} database={database} />
        <Questions
          // setShowFeedback={setShowFeedback}
          batchId={batchId}
          database={database}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
        />
      </div>
    </div>
  );
};

export default Index;
