import { CompanyConnectType } from '../types';
import AboutCompany from './AboutCompany';
import CompanyDataHeader from './layout/CompanyDataHeader';
import MentorConnects from './layout/MentorConnects';
import QuickTipsSection from './layout/QuickTipsSection';

import MentorEvents from './layout/MentorEvents';

const CompanyConnectPanel = ({
  aboutData,
  mentorData,
  eventData,
}: CompanyConnectType) => {
  return (
    <div className=' md:border rounded-b-lg text-textColor-lighter'>
      <CompanyDataHeader eventData={eventData} />
      <div className='px-4 md:px-8 space-y-14'>
        <AboutCompany aboutData={aboutData} mentorData={mentorData} />
        <MentorConnects />
        <QuickTipsSection />
        <MentorEvents eventData={eventData} />
      </div>
    </div>
  );
};

export default CompanyConnectPanel;
