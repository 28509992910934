import React from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';

const MilestonesCard = ({
  setIsTaskView,
  projectData,
}: {
  setIsTaskView: any;
  projectData: any;
}) => {
  const tasks = projectData?.tasks || [];

  return (
    <>
      <div className='mt-4 md:mt-6'>
        <div className='grid  grid-cols-12 gap-8'>
          <div className='col-span-12 xl:col-span-6'>
            <div className='p-1 border-r-2 text-center text-primary font-semibold border rounded mb-2'>
              <p>Ongoing</p>
            </div>
            <div className='space-y-8'>
              {tasks.map((data: any) => {
                return !data.solution ? (
                  <div
                    key={data._id}
                    onClick={() => setIsTaskView(data)}
                    className={classNames(
                      'col-span-12 lg:col-span-6 cursor-pointer mb-4 lg:mb-8 bg-white border-2 hover:border-primary pb-4  hover:bg-primary-lightest',
                      { 'pointer-events-none': data.isLocked }
                    )}
                  >
                    <img
                      src='https://res.cloudinary.com/belong/image/upload/v1670239433/new%20b2b/image_323_lt9pjm.svg'
                      alt='banner'
                      className='object-cover w-full border-l-4 border-yellow-300'
                    />
                    <div className=' p-4 grid grid-cols-12 gap-x-4 gap-y-2'>
                      <div className='col-span-12 md:col-span-8'>
                        <p className='text-primary font-semibold'>
                          Due Date:{' '}
                          {data.dueDateTime &&
                            format(
                              new Date(data.dueDateTime),
                              'do LLLL yyyy h:m b'
                            )}
                        </p>
                        <div className='flex items-center space-x-2 mt-2'>
                          <div className='h-3 w-3 bg-primary rounded-full '></div>
                          <p className='text-sm  font-medium'>{data.title}</p>
                        </div>
                        <p className='text-xs mt-2 font-semibold text-center text-primary bg-white max-w-fit px-2 py-1 rounded-2xl'>
                          {data.level}
                        </p>
                        <div className='flex flex-wrap lg:flex-nowrap  gap-2 lg:gap-4 text-xs mt-2 items-center'>
                          <p className='flex-none'>Skills you will be using</p>
                          <div className='flex flex-wrap  gap-2 lg:gap-4'>
                            {data.skillId.map((skill: any) => {
                              return (
                                <p
                                  key={skill._id}
                                  className='bg-primary/10 min-w-fit px-2 py-1 rounded-2xl font-semibold text-xs'
                                >
                                  {skill.title}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      {data.isLocked ? (
                        <div className='col-span-12 md:col-span-4  border border-primary h-8 w-1/3 md:w-full mx-auto rounded-lg py-2 bg-white mt-4 sm:mt-0'>
                          <img
                            src='https://res.cloudinary.com/belong/image/upload/v1670311998/new%20b2b/bxs_lock-alt_s9g7ds.svg'
                            alt='lock'
                            className='mx-auto'
                          />
                          <div className='text-sm text-primary text-center mt-1 whitespace-nowrap'>
                            Unlocks in Week - {data.week}
                          </div>
                        </div>
                      ) : (
                        <div className='col-span-12 md:col-span-4 md:text-right'>
                          <button className=' text-primary border border-primary bg-white rounded-lg px-2 py-2  mx-auto  text-center text-xs font-semibold mt-4 sm:mt-0 '>
                            Complete Now
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className='col-span-12 xl:col-span-6 '>
            <div className='p-1 border-r-2 text-center text-primary font-semibold border rounded mb-2'>
              <p>Completed</p>
            </div>
            <div className='space-y-8'>
              {tasks.map((data: any) => {
                return data.solution ? (
                  <div
                    key={data._id}
                    onClick={() => setIsTaskView(data)}
                    className={classNames(
                      'col-span-12 lg:col-span-6 cursor-pointer mb-4 lg:mb-8 bg-primary-lightest',
                      { grayscale: data?.solution }
                    )}
                  >
                    <img
                      src='https://res.cloudinary.com/belong/image/upload/v1670239433/new%20b2b/image_323_lt9pjm.svg'
                      alt='banner'
                      className='object-cover w-full border-l-4 border-yellow-300'
                    />
                    <div className=' p-4 grid grid-cols-12 gap-x-8'>
                      <div className='col-span-12 md:col-span-8'>
                        <p className='text-primary font-semibold'>
                          Due Date:{' '}
                          {data.dueDateTime &&
                            format(
                              new Date(data.dueDateTime),
                              'do LLLL yyyy h:m b'
                            )}
                        </p>
                        <div className='flex items-center space-x-2 mt-2'>
                          <div className='h-3 w-3 bg-primary rounded-full '></div>
                          <p className='text-sm  font-medium'>{data.title}</p>
                        </div>
                        <p className='text-xs mt-2 font-semibold text-center text-primary bg-white max-w-fit px-2 py-1 rounded-2xl'>
                          {data.level}
                        </p>
                        <div className='flex flex-wrap lg:flex-nowrap  gap-2 lg:gap-4 text-xs mt-2 items-center'>
                          <p className='flex-none'>Skills you will be using</p>
                          <div className='flex flex-wrap lg:flex-nowrap gap-2 lg:gap-4'>
                            {data.skillId.map((skill: any) => {
                              return (
                                <p
                                  key={skill._id}
                                  className='bg-primary/10 min-w-fit px-2 py-1 rounded-2xl font-semibold text-xs'
                                >
                                  {skill.title}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className='col-span-12 md:col-span-4 md:text-right'>
                        <button className=' text-primary border border-primary bg-white rounded-lg px-2 py-2 mx-auto text-center text-xs font-semibold mt-4 sm:mt-0 '>
                          {data.solution && data.score
                            ? ' View Feedback'
                            : 'Completed'}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MilestonesCard;
