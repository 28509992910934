const SubmissionCard = ({
  userSubmissionRateData,
}: {
  userSubmissionRateData: any;
}) => {
  const {
    submitPercentage,
    centurySubmission,
    companySubmission,
    totalCenturyTask,
    totalCompanyTask,
  } = userSubmissionRateData;
  return (
    <div>
      <div className='border-2 border-dark/10 rounded-sm space-y-6 p-2 pb-6 w-80 min-h-[300px]'>
        {/* first area start */}
        <div className='flex justify-between items-center'>
          <p className='text-primary font-semibold text-lg'>Submission Rate</p>
          <p className='text-white font-semibold bg-primary border rounded-2xl min-w-fit px-2'>
            {submitPercentage.toFixed(2)}%
          </p>
        </div>
        {/* first area end */}

        {/* second area start */}
        <div>
          <div className='w-full  h-4 flex'>
            <div
              className={`bg-primary/80 rounded-l-2xl h-4 w-[${Math.floor(
                (centurySubmission / totalCenturyTask) * 100
              )}%]`}
            ></div>
            <div
              className={`bg-primary/60 rounded-r-2xl h-4 w-[${
                Math.floor(companySubmission / totalCompanyTask) * 100
              }%]`}
            ></div>
          </div>
        </div>
        {/* second area end */}
        {/* third area start */}
        <div className='space-y-4 font-semibold'>
          {/*21st Century Lab */}
          {totalCenturyTask > 0 && (
            <div className='bg-primary/80 border  rounded-2xl flex items-center  h-8 w-full'>
              <p className='bg-white  border-2  border-primary rounded-full h-11 w-11 p-1 text-primary flex flex-none items-center justify-center'>
                {centurySubmission}/{totalCenturyTask}
              </p>
              <div className='flex w-full items-center justify-between'>
                <p className='ml-4'>Assignments</p>
              </div>
            </div>
          )}
          {/* Project Tasks */}
          {totalCompanyTask > 0 && (
            <div className='bg-primary/60 border  rounded-2xl flex items-center  h-8 w-full'>
              <p className='bg-white  border-2  border-primary rounded-full h-11 w-11 p-1 text-primary flex flex-none items-center justify-center'>
                {companySubmission}/{totalCompanyTask}
              </p>
              <div className='flex w-full items-center justify-between'>
                <p className='ml-4'>Project Tasks</p>
              </div>
            </div>
          )}
          {/* Domain Quiz  */}
          {/* <div className='bg-primary/40 border  rounded-2xl flex items-center  h-8 w-full'>
              <p className='bg-white  border-2  border-primary rounded-full h-11 w-11 p-1 text-primary flex flex-none items-center justify-center'>
                3/4
              </p>
              <div className='flex w-full items-center justify-between'>
                <p className='ml-4'>Domain Quiz</p>
              </div>
            </div> */}
        </div>
        {/* third area end */}
      </div>
    </div>
  );
};

export default SubmissionCard;
