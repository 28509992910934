import { format } from 'date-fns';

import { useReactToPrint } from 'react-to-print';

import { useRef } from 'react';

const belongLogo =
  'https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png';

const VgaCertificate = ({
  batchData,
  isAllTaskDone,
}: {
  batchData: any;
  isAllTaskDone: boolean;
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const userDetails = batchData.users[0];
  const mentorSign =
    userDetails.coach.coachSign || userDetails.mentor.mentorSign;
  const completionStatus =
    userDetails.completionStatus === 'Not Applicable' && isAllTaskDone
      ? 'Completion'
      : userDetails.completionStatus;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const certificateText = 'Certificate of ' + completionStatus;

  const areaText = userDetails.area.title;
  const companyLogo = userDetails.company.logoUrl;
  const topRightLogo =
    batchData.type === 'University' ? belongLogo : batchData.logoUrl;

  const durationText = `for the duration of ${format(
    new Date(batchData.startDate),
    'do LLLL y'
  )} to ${format(new Date(batchData.endDate), 'do LLLL y')}.`;

  const detailsText = `has successfully completed the Virtual Global Apprenticeship
  Program in ${areaText} ${durationText}  `;

  const certificateWidth = 900;
  const certificateHeight = 600;
  const printStyles = `
  @page {
    size: ${certificateWidth}px ${certificateHeight}px;
    margin: 0;
  }

  @media print {
    body {
      margin: 0;
    }

    .certificate-container {
      width: ${certificateWidth}px;
      height: ${certificateHeight}px;
      padding: 0;
      margin: 0;
      page-break-after: always;
    }
  }
`;

  return (
    <>
      {completionStatus === 'Not Applicable' ? (
        <div className='m-6'>
          <p className='text-primary text-2xl font-bold mb-4 mt-4 text-center'>
            Thank you for submitting your feedback.
          </p>
          <div className='text-center mb-6'>
            Please submit all the company project tasks and assignments to
            access your VGA Certificate.
          </div>
        </div>
      ) : (
        <>
          <div className='flex flex-col items-center p-2 border-2 border-b-0 bg-gray-50'>
            <button
              className='mx-auto rounded mt-3 text-gray-100  text-sm px-8 py-2 bg-primary hover:bg-primary-light hover:text-gray-700 font-bold'
              onClick={handlePrint}
            >
              Download Certificate
            </button>
          </div>
          <style>{printStyles}</style>
          <div
            ref={componentRef}
            style={{ minWidth: '900px', maxWidth: '900px' }}
            className='w-3/4 mx-auto px-4 border-2 border-t-0 border-b-0 pb-4 bg-gray-50'
          >
            <div
              style={{ background: 'white' }}
              className=' flex justify-center mx-auto '
            >
              <div className=' border-4 border-primary bg-white m-8'>
                <div className='m-1.5 border-2 border-primary p-4'>
                  <div>
                    <div className='flex justify-between mb-8'>
                      <div className='flex'>
                        <img
                          src='https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png'
                          alt='Logo'
                          className='h-12'
                        />
                        <img
                          src={companyLogo}
                          alt='Logo'
                          className='h-12 ml-2'
                        />
                      </div>

                      <img src={topRightLogo} alt='Logo' className='h-12' />
                    </div>

                    <div className='flex flex-col justify-center px-12 '>
                      <p className=' text-6xl mb-4 text-center tracking-wide font-dancing'>
                        {certificateText}
                      </p>
                      <span className='px-64'>{''}</span>
                      <p className='text-center text-md  font-semibold mt-3 font-lora'>
                        This is to certify that
                      </p>
                      <div className='text-center tracking-wider text-2xl font-bold font-lora mt-3'>
                        {userDetails.name.toUpperCase()}
                      </div>

                      <p className='text-center text-md font-semibold mt-3 font-lora'>
                        {detailsText}
                      </p>
                    </div>
                    <div className='flex justify-between px-24 mt-12 font-lora'>
                      <div className='flex flex-col items-center'>
                        <img src={mentorSign} alt='Logo' className='h-12' />
                        <p className='border-2 px-4 border-b-0 border-l-0 border-r-0  border-gray-600 font-semibold tracking-wider  pt-2 text-center text-sm mr-2'>
                          Industry Mentor
                        </p>
                      </div>
                      <div className='flex flex-col items-center mb-6'>
                        <div className='flex flex-row'>
                          <img
                            src='https://res.cloudinary.com/belong/image/upload/v1643353663/uploaded_resources/One_no4yyb.gif'
                            alt='Logo'
                            className='h-12'
                          />
                          <img
                            src='https://res.cloudinary.com/belong/image/upload/v1643353575/uploaded_resources/Two_yy5ee6.gif'
                            alt='Logo'
                            className='h-12'
                          />
                        </div>
                        <p className='border-2 px-4 border-b-0 border-l-0 border-r-0  border-gray-600 font-semibold tracking-wider text- pt-2 text-center text-sm'>
                          Belong Founders
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VgaCertificate;
