import { useGetOnboardingQuery } from 'components/dashboard/panel/panelSlice';

const ScoreCertificationHeader = ({
  batchId,
  userSubmissionRateData,
}: {
  batchId: string;
  userSubmissionRateData: any;
}) => {
  const { data } = useGetOnboardingQuery();
  const batch = data?.vga?.find((batch: any) => batch._id === batchId);

  const { totalSubmittedTasks, totalTasks } = userSubmissionRateData;

  return (
    <div className='md:flex flex-wrap md:justify-between bg-gradient-to-b from-primary/10 to-white p-4 md:px-8 md:pt-6'>
      <div className='flex space-x-2 md:space-x-4 items-center text-primary'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1672979188/new%20b2b/carbon_analytics_k0yk59.svg'
          alt='logo'
        />
        <p className='text-sm font-semibold'>
          Your VGA Journey's Holistic Performance & Certification
        </p>
      </div>

      <div className='mt-2 md:mt-0'>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Certification & Feedback
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          VGA Performance-{' '}
          <span className='text-primary'>{batch?.vgaScore}%</span>
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Task Summissions-
          <span className='text-primary'>
            {totalSubmittedTasks}/{totalTasks}
          </span>
        </p>
      </div>
    </div>
  );
};

export default ScoreCertificationHeader;
