import { DomainTestResponsesContext } from 'hooks/useDomainTestResponsesContext';
import { useInterval } from 'hooks/useInterval';
import { useTimeout } from 'hooks/useTimeout';
// import { useTimeout } from 'hooks/useTimeout';
import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useFinishTestMutation,
  useUpdateResponsesMutation,
} from '../domain/DomainQuizSlice';

import Questions from './questions';
import { reducer } from './utils/helper';
import { initialState } from './utils/types';

const Index: React.FC<{ data: any }> = ({ data }) => {
  const [updateResponse] = useUpdateResponsesMutation();
  const [finishTest] = useFinishTestMutation();
  const [timeLeft, setTimeLeft] = useState(data.timeLeft);

  const navigate = useNavigate();
  const domainResponsesInitialState: initialState = {
    domain: data.domain.title,
    minutesLeft: 0,
    secondsLeft: 0,
    currentQuestion: 0,
    responses: data.responses,
    totalQuestions: data.totalQuestions,
    allSkills: data.allSkills,
  };

  const [state, dispatch] = useReducer(reducer, domainResponsesInitialState);

  function finishTestFn() {
    finishTest({})
      .unwrap()
      .then((res: any) => {
        navigate('/quiz/domain/scorecard');
      });
  }

  useEffect(() => {
    updateResponse({ responses: state.responses });
  }, [state.responses, updateResponse]);

  // Set timeout to finish test
  useTimeout(() => {
    finishTestFn();
  }, data.timeLeft * 1000);
  //Update timer
  useInterval(() => {
    const minutesLeft = Math.floor(timeLeft / 60);
    const secondsLeft = timeLeft % 60;
    dispatch({
      type: 'UPDATE_REMAINING_TIME',
      payload: { minutesLeft, secondsLeft },
    });
    setTimeLeft((timeLeft: number) => timeLeft - 1);
  }, 1000);

  return (
    <DomainTestResponsesContext.Provider value={{ state, dispatch }}>
      <div>
        <Questions />
      </div>
    </DomainTestResponsesContext.Provider>
  );
};

export default Index;
