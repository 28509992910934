import Card from './Card';
import classNames from 'classnames';

interface allProps {
  data: any;
  isFetching: any;
}

const AllCapstone = (props: allProps) => {
  return (
    <>
      <div
        className={classNames(
          'grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-6 my-6 md:my-12',
          { 'animate-pulse ': props.isFetching }
        )}
      >
        {props.data?.map((cap: any, index: number) => {
          return (
            <Card
              key={cap._id}
              _id={cap._id}
              title={cap.title}
              level={cap.level}
              logo={cap.companyId.logoUrl}
              country={cap.companyId.country}
              industry={cap.companyId?.industryId?.title}
              skills={cap.skillId}
              domain={cap.domainId?.title}
              tasks={cap.tasks || []}
            />
          );
        })}
      </div>
    </>
  );
};

export default AllCapstone;
