import React, { useReducer } from 'react';
import CoachSupportPanel from '../coach-support/CoachSupportPanel';
import CompanyConnectPanel from '../company-connect/CompanyConnectPanel';
import {
  getCoachConnectData,
  getCompanyConnectData,
  getMasterclassConnectData,
  getTabsData,
  getProjectTaskData,
  getCenturyLabData,
  getQueryTabData,
} from '../helper';
import JourneyPanel from '../journey/JourneyPanel';
import MasterclassPanel from '../masterclass/MasterclassPanel';
import ProjectTasksPanel from '../project-tasks/ProjectTasksPanel';
import QueryPanel from '../query/QueryPanel';
import CenturyLabPanel from '../21st-century-lab/CenturyLabPanel';
import DomainQuizPanel from '../domain-quiz/DomainQuizPanel';
import {
  CoachConnectType,
  CompanyConnectType,
  InitialTab,
  MasterclassConnectType,
  TabType,
  QueryTabType,
} from '../types';
import ScoreCertificationPanel from '../score-certification/ScoreCertificationPanel';

export default function useTabs(data: any) {
  const batchData = data?.batches[0];
  const batchDomain = batchData?.domainId;
  const userId = data?._id;
  const tabProps: TabType = getTabsData(batchData);
  const userData = batchData.users[0];

  const feedbackGiven = userData.vgaFeedback;
  const companyConnectProps: CompanyConnectType = getCompanyConnectData(
    batchData.users[0],
    batchData.events,
    userId,
    data?.email
  );
  const coachConnectProps: CoachConnectType = getCoachConnectData(
    batchData.users[0],
    batchData.events,
    userId,
    data?.email
  );
  const masterclassConnectProps: MasterclassConnectType =
    getMasterclassConnectData(
      batchData.users[0],
      batchData.events,
      userId,
      data?.email
    );

  const initialState: { basicDetails: any; tabs: InitialTab[] } = {
    basicDetails: {},
    tabs: [],
  };

  //Add basic details that can be accessed via the state
  initialState.basicDetails = {
    userId,
    useremail: data?.email,
    batchId: batchData?._id,
    partnerId: data?.partnerId,
    coachNotifications: 0,
  };
  const projectTaskProps = getProjectTaskData({
    userData,
    projects: batchData.projects,
  });
  const centuryLabProps = getCenturyLabData({
    projects: batchData.projects,
  });

  const queryPanelProps: QueryTabType = getQueryTabData(batchData);

  const addInitialState: (
    id: number,
    text: string,
    component: React.ReactElement,
    tabStatus?: 'ACTIVE' | 'INACTIVE'
  ) => void = (id, text, component, tabStatus = 'INACTIVE') => {
    initialState.tabs.push({
      id: id,
      text: text,
      status: tabStatus,
      component: component,
    });
  };

  //First screen
  //The order of the screen matters, please check the files where tab id is being used
  addInitialState(1, 'Journey', <JourneyPanel data={data} />, 'ACTIVE');

  tabProps.companyConnect &&
    addInitialState(
      2,
      'Company Connect',
      <CompanyConnectPanel {...companyConnectProps} />
    );

  tabProps.projectTask &&
    addInitialState(
      3,
      'Company Project',
      <ProjectTasksPanel {...projectTaskProps} />
    );

  tabProps.coachSupport &&
    addInitialState(
      4,
      'Coach Support',
      <CoachSupportPanel {...coachConnectProps} batchId={batchData._id} />
    );

  tabProps.masterclass &&
    addInitialState(
      5,
      'Masterclass',
      <MasterclassPanel {...masterclassConnectProps} />
    );
  tabProps.centuryLab &&
    addInitialState(6, 'Assignments', <CenturyLabPanel {...centuryLabProps} />);
  addInitialState(
    7,
    'Domain Quiz',
    <DomainQuizPanel
      userData={userData}
      batchId={batchData._id}
      batchDomain={batchDomain}
      openQuiz={tabProps.domainQuiz}
    />
  );
  tabProps.coachSupport &&
    addInitialState(8, 'Query', <QueryPanel {...queryPanelProps} />);
  addInitialState(
    9,
    'Score & Certification',
    <ScoreCertificationPanel
      batchData={batchData}
      data={data}
      {...projectTaskProps}
      feedbackGiven={feedbackGiven}
    />
  );

  const reducer = (state: any, action: { type: string; payload: number }) => {
    switch (action.type) {
      case 'CHANGE_TAB':
        const changedTabs = state.tabs.map((tab: InitialTab) => {
          if (tab.id === action.payload) {
            return { ...tab, status: 'ACTIVE' };
          } else {
            return { ...tab, status: 'INACTIVE' };
          }
        });
        return { ...state, tabs: changedTabs };

      case 'UPDATE_COACH_NOTIFICATIONS':
        return {
          ...state,
          basicDetails: {
            ...state.basicDetails,
            coachNotifications: action.payload,
          },
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  return [state, dispatch];
}
